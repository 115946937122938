export const Modulo = Object.freeze({
  CCG: 0,
  CORRETOR: 1,
  PROPOSTA: 2,
  APOLICE: 4,
  COCORRETAGEM: 6,
  RESSEGURO: 7,
  PARAMETRIZACAO: 8,
  ENDOSSO: 9,
  TOMADOR: 10,
  SEGURADO: 11,
  COSSEGURO:12,
});
